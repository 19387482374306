<template>
  <div>
    <div ref="basicMapbox" style="position: relative" class="w100"></div>
  </div>
</template>

<script>
  import MapboxLanguage from "@mapbox/mapbox-gl-language";
  import mapboxgl from "mapbox-gl";
  import { imgAddLocaHost } from "@/libs/util";
  import "mapbox-gl/dist/mapbox-gl.css";
  export default {
    data() {
      return {
        map: null,
      };
    },
    mounted() {
      this.$nextTick(() => {
        this.init();
      });
    },
    methods: {
      init() {
        const start = {
            zoom: 14,
            center: [104.35, 28.83],
            pitch: 70,
            bearing: 250,
          },
          end = {
            center: [118.726533, 32.012005],
            zoom: 16.5,
            bearing: 20, //目标方位角
            pitch: 75,
          };

        mapboxgl.accessToken = "pk.eyJ1IjoicGVuNDU2IiwiYSI6ImNsbzN5N2Y2ZjF3MTEybmp5dDRqOHZ3MnkifQ.QIZkJDH6kdSDBK_pqE9rQA";
        let map = new mapboxgl.Map({
          container: this.$refs.basicMapbox,
          style: "mapbox://styles/mapbox/satellite-streets-v12",
          ...start,
          // projection: 'globe', // 为 3D 地球
          // antialias: false, //抗锯齿，通过false关闭提升性能
        });
        map.addControl(new MapboxLanguage({ defaultLanguage: "zh-Hans" }));
        // ### 添加导航控制条
        map.addControl(new mapboxgl.NavigationControl(), "top-left");

        map.on("style.load", function () {
          map.addSource("mapbox-dem", {
            type: "raster-dem",
            url: "mapbox://mapbox.mapbox-terrain-dem-v1",
            tileSize: 512,
            maxzoom: 14,
          });
          // add the DEM source as a terrain layer with exaggerated height
          map.setTerrain({ source: "mapbox-dem", exaggeration: 1.5 });
          // map.setFog({});
          // map.flyTo({
          //     ...end,
          //     duration: 12000,
          //     essential: true,
          // });
        });

        let images = imgAddLocaHost("/static/ps.png");
        map.on("load", () => {
          const el = document.createElement("img"); //这里可以是任意标签
          el.className = "marker";
          el.src = images; //图片路径赋值

          //添加气泡弹窗
          let mhtml = `<div class="malert">
									<h2 class="pti1">屏山县</h2>
									<div class="pti2">屏山县，隶属四川省宜宾市。位于四川省南缘，金沙江下游北岸，岷江下游两岸，东界叙州区，西邻凉山州雷波县、马边彝族自治县，北连沐川县，南与绥江县隔金沙江相望。总面积1504平方千米。2022年，屏山县常住人口24.5万人。有少数民族30个。屏山县下辖8个镇、3个乡。</div>
								</div>`;

          let popup1 = new mapboxgl.Popup().setHTML(mhtml);

          // 添加自定义标记点
          new mapboxgl.Marker(el).setLngLat([104.35, 28.83]).setPopup(popup1).addTo(map);
        });
        this.map = map;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .w100 {
    width: 100%;
    height: 800px;
  }
</style>
